.dashboard-container {
    display: flex;
    flex-direction: row;
}
.dashboard-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #F0F2F5;
}

@media (max-width: 767px) {
    .hidden-mobile {
      display: none;
    }
}